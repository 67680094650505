#mainAppDiv #header {
	text-align: center;
	justify-content: center;
}
#mainAppDiv #header input {
	background-color: inherit;
	width: auto;
	border: none;
	font: inherit;
	font-family: inherit;
}

.blink {
	width: 7px;
	height: 15px;
	animation: blink 0.8s infinite;
	-webkit-animation: blink 0.8s infinite;
	margin: 0;
}

@keyframes blink {
	0% {background: inherit;}
	50% {background: lime;}
	100% {background: inherit;}
}

@-webkit-keyframes blink {
	0% {background: inherit;}
	50% {background: lime;}
	100% {background: inherit;}
}